import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'
import {
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
  Box,
} from '@mui/material'

import { Icons } from '../../assets'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 'var(--spacing-m)',

    width: '100%',
    maxWidth: { md: 'var(--content-M-max-width)' },
    borderRadius: 'var(--corners-s)',
    padding: 'var(--spacing-m)',
    '& .MuiIconButton-root': {
      color: 'inherit',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--spacing-m)',
  },
  icon: {
    color: 'inherit !important',
  },
}

const VARIANT_CONFIG = {
  info: {
    icon: <Icons.ExclamationCircle />,
    sx: {
      color: 'neutral.white',
      backgroundColor: 'info.main',
    },
  },
  warning: {
    icon: <Icons.ExclamationCircle />,
    sx: {
      color: 'neutral.black',
      backgroundColor: 'warning.main',
    },
  },
  error: {
    icon: <Icons.ExclamationTriangle />,
    sx: {
      color: 'neutral.white',
      backgroundColor: 'error.main',
    },
  },
  success: {
    icon: <Icons.CheckCircle />,
    sx: {
      color: 'neutral.black',
      backgroundColor: 'success.main',
    },
  },
}

const toasterPropTypes = {
  variant: PropTypes.oneOf(['info', 'error', 'success', 'warning']).isRequired,
  message: PropTypes.string.isRequired,
  withClose: PropTypes.bool,
}

const Snackbar = forwardRef((props, ref) => {
  const { variant = 'info', message, onDismiss, withClose = true } = props

  const isMobile = !useMediaQuery(useTheme().breakpoints.up('md'))

  return (
    <SnackbarContent
      ref={ref}
      style={
        // NOTE: special styles to place snackbar above the bottom-bar in mobile version
        isMobile
          ? {
              position: 'relative',
              bottom: 78,
            }
          : {
              // width: 'var(--content-S-max-width)',
            }
      }
    >
      <Box
        sx={{
          ...styles.root,
          ...(VARIANT_CONFIG[variant]?.sx ? VARIANT_CONFIG[variant].sx : {}),
        }}
      >
        <Box sx={styles.content}>
          {VARIANT_CONFIG[variant]?.icon && (
            <IconButton>{VARIANT_CONFIG[variant]?.icon}</IconButton>
          )}

          <Typography>{message}</Typography>
        </Box>

        {withClose && (
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={onDismiss}
            sx={styles.icon}
          >
            <Icons.Close />
          </IconButton>
        )}
      </Box>
    </SnackbarContent>
  )
})

Snackbar.propTypes = toasterPropTypes

export default Snackbar
