import { backofficeEndpoint } from '../../config'

import fetchWithAuthorization, {
  InvalidTokenError,
} from '../fetchWithAuthorization'
import InvalidOldPasswordError from './InvalidOldPasswordError'

export const getPersonalData = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user`, {
    headers: authHeaders,
  })
  if (response.ok) {
    return await response.json()
  }

  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const getArticlesBySection = fetchWithAuthorization(
  async (authHeaders, sectionId, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      offset: pagination?.offset,
      limit: pagination?.limit,
    })
    const response = await fetch(
      `${backofficeEndpoint}/articles/section/${sectionId}?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticlesBySearch = fetchWithAuthorization(
  async (authHeaders, searchValue, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      value: searchValue,
      offset: pagination?.offset,
      limit: pagination?.limit,
    })
    const response = await fetch(
      `${backofficeEndpoint}/articles/search?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticlesForTopic = fetchWithAuthorization(
  async (authHeaders, topicId, pagination = {}) => {
    const params = {
      offset: pagination?.offset,
      limit: pagination?.limit,
    }
    const response = await fetch(
      `${backofficeEndpoint}/articles/topic/${topicId}?${new URLSearchParams(
        params,
      ).toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getArticlesViewedByUser = fetchWithAuthorization(
  async (authHeaders, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      offset: pagination?.offset,
      limit: pagination?.limit,
    })
    const response = await fetch(
      `${backofficeEndpoint}/user/viewed-article?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const getUserFavoriteArticles = fetchWithAuthorization(
  async (authHeaders, pagination = {}) => {
    const paginationParams = new URLSearchParams({
      limit: pagination?.limit,
      offset: pagination?.offset,
    })
    const response = await fetch(
      `${backofficeEndpoint}/user/favorite-article?${paginationParams.toString()}`,
      {
        headers: authHeaders,
      },
    )
    if (response.ok) {
      return await response.json()
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const updatePersonalData = fetchWithAuthorization(
  async (authHeaders, personalData) => {
    const response = await fetch(`${backofficeEndpoint}/user/personal-data`, {
      method: 'PUT',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(personalData),
    })
    if (response.ok) {
      return
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
  },
)

export const updateCommunications = fetchWithAuthorization(
  async (authHeaders, communicationsData) => {
    const response = await fetch(`${backofficeEndpoint}/user/communications`, {
      method: 'PUT',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(communicationsData),
    })
    if (response.ok) {
      return
    }

    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    throw new Error()
  },
)

export const changePassword = fetchWithAuthorization(
  async (authHeaders, { oldPassword, newPassword }) => {
    const response = await fetch(`${backofficeEndpoint}/user/change-password`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    })

    if (response.ok && response.status === 200) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 400) {
      const error = await response.json()
      if (error.code === 'NotAuthorizedException') {
        throw new InvalidOldPasswordError()
      }
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    throw new Error('Something went wrong')
  },
)

const checkIsBankCardExpired = ({
  month: expiryDateMonth,
  year: expiryDateYear,
}) =>
  expiryDateYear < new Date().getFullYear() ||
  (expiryDateYear === new Date().getFullYear() &&
    expiryDateMonth - 1 < new Date().getMonth())

export const fetchBankCard = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/bank-card`, {
    method: 'GET',
    headers: authHeaders,
  })
  if (response.ok) {
    const data = await response.json()

    const formattedData = {
      ...data,
      expiryDate: `${data.expiryDate.month
        .toString()
        .padStart(2, '0')}/${data.expiryDate.year.toString().slice(2)}`,
      isExpired: checkIsBankCardExpired(data.expiryDate),
    }
    return formattedData
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const createUserLicence = fetchWithAuthorization(
  async (authHeaders, licenceToken) => {
    const response = await fetch(`${backofficeEndpoint}/user/licence`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ licence: licenceToken }),
    })

    if (response.ok && response.status === 201) {
      return await response.json()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    return null
  },
)

export const fetchUserLicence = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/licence`, {
    method: 'GET',
    headers: authHeaders,
  })

  if (response.ok) {
    return await response.json()
  }
  if (!response.ok && response.status === 404) {
    return null
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const sendEmail = fetchWithAuthorization(
  async (authHeaders, emailData) => {
    const response = await fetch(`${backofficeEndpoint}/user/send-email`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    })

    if (response.ok && response.status === 204) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    if (!response.ok) {
      throw new Error('email could not be sent')
    }
  },
)

// export const validateApplePayMerchant = fetchWithAuthorization(
//   async (authHeaders, validationUrl) => {
//     const response = await fetch(
//       `${backofficeEndpoint}/user/apple-pay/merchant-validation`,
//       {
//         method: 'POST',
//         headers: {
//           ...authHeaders,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ validationUrl }),
//       },
//     )

//     if (response.ok) {
//       return response.json()
//     }
//     if (!response.ok && response.status === 401) {
//       throw new InvalidTokenError(response.statusText)
//     }
//     throw new Error(
//       `Error fetching ApplePay merchant session: ${response.error}`,
//     )
//   },
// )
