import { SnackbarProvider, useSnackbar } from 'notistack'

import { useTexts } from '../texts'
import { Snackbar } from './components'

export const NotificationsProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={3000}
      transitionDuration={{ enter: 425, exit: 425 }}
    >
      {children}
    </SnackbarProvider>
  )
}

export const useNotifications = () => {
  const notistack = useSnackbar()
  const texts = useTexts()

  const dispatchSnackbarWithVariant =
    (variant, defaultMessage = '') =>
    (config) =>
      notistack.enqueueSnackbar(config?.message ?? defaultMessage, {
        content: (key, message) => (
          <Snackbar
            id={key}
            message={message}
            variant={variant}
            withClose={config?.withClose}
            onDismiss={() => notistack.closeSnackbar(key)}
          />
        ),
        // persist: true,
      })

  return {
    ...notistack,
    dispatchSuccess: dispatchSnackbarWithVariant(
      'success',
      texts.getSaveSucceed(),
    ),
    dispatchError: dispatchSnackbarWithVariant('error', texts.getErrorRetry()),
  }
}
