import PropTypes from 'prop-types'
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  Button,
} from '@mui/material'

const toggleButtonGroupPropTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  exclusive: PropTypes.bool,
  groupAriaLabel: PropTypes.string,
  sx: PropTypes.object,
}

const ToggleButtonGroup = ({
  isLoading = false,
  options,
  selectedOption,
  setSelectedOption,
  exclusive = true,
  groupAriaLabel,
  sx = {},
}) => {
  return (
    <MuiToggleButtonGroup
      color='primary'
      value={selectedOption}
      exclusive={exclusive}
      onChange={(_event, newValue) =>
        setSelectedOption(options.find((option) => option.value === newValue))
      }
      aria-label={groupAriaLabel}
      sx={sx}
    >
      {options.map((option) => (
        <Button
          sx={{ border: `1px solid black` }}
          key={option.value}
          aria-label={`groupAriaLabel-${option.label}`}
          value={option.value}
          {...(selectedOption === option.value
            ? { color: 'secondary', variant: 'contained' }
            : { color: 'inherit', variant: 'outlined' })}
          disabled={isLoading}
          onClick={(event) => {
            if (selectedOption !== option.value) {
              setSelectedOption(
                options.find((option) => option.value === event.target.value)
                  ?.value,
              )
            }
          }}
        >
          {option.label}
        </Button>
      ))}
    </MuiToggleButtonGroup>
  )
}

ToggleButtonGroup.propTypes = toggleButtonGroupPropTypes

export default ToggleButtonGroup
