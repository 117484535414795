import PropTypes from 'prop-types'
import { useReducer } from 'react'
import { Typography } from '@mui/material'

import * as a9 from '../../../../analytics'
import { getTexts } from '../../../../texts'
import { User } from '../../../../business'
import { useNotifications } from '../../../notifications'
import { ToggleButtonGroup } from '../../../components'

const styles = {
  '--card-width': { xs: '21.4375rem', md: '30rem' },
  width: { xs: 'min(100%, var(--card-width))', md: 'var(--card-width)' },
  display: 'flex',
  '& > .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.MuiButtonBase-root.MuiButton-root':
    {
      width: '50%',
    },
}

const texts = getTexts()

const COMMUNICATIONS_FRECUENCY = {
  WEEKLY: 'weekly',
  NEVER: 'never',
}

const COMMUNICATIONS_OPTIONS = {
  [COMMUNICATIONS_FRECUENCY.WEEKLY]: {
    value: COMMUNICATIONS_FRECUENCY.WEEKLY,
    label: texts.weekly(),
  },
  [COMMUNICATIONS_FRECUENCY.NEVER]: {
    value: COMMUNICATIONS_FRECUENCY.NEVER,
    label: texts.never(),
  },
}

const ACTIONS = {
  LOADING: 'LOADING',
  UPDATE: 'UPDATE',
}

const topicsFrequencyReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOADING:
      return { ...state, isLoading: true }
    case ACTIONS.UPDATE:
      return { ...state, isLoading: false, value: action.payload.value }
    case ACTIONS.ERROR:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

const CommunicationsPropTypes = {
  initialData: PropTypes.shape({
    topicsOfInterest: PropTypes.oneOf(Object.values(COMMUNICATIONS_FRECUENCY)),
  }),
}

const Communications = ({ initialData }) => {
  const notifications = useNotifications()

  const [topicsFrecuencyState, dispatchTopicsFrecuencyState] = useReducer(
    topicsFrequencyReducer,
    initialData.topicsOfInterest,
    (initialValue) => ({
      isLoading: false,
      value: initialValue,
    }),
  )

  const update = async (topicsCommunicationFrecuency) => {
    dispatchTopicsFrecuencyState({ type: ACTIONS.LOADING })

    try {
      await User.updateCommunications({
        topicsOfInterest: topicsCommunicationFrecuency,
      })

      dispatchTopicsFrecuencyState({
        type: ACTIONS.UPDATE,
        payload: { value: topicsCommunicationFrecuency },
      })
      a9.dispatchEvent(a9.EVENTS.PD_SUBMIT_TOI_COMMUNICATIONS_CONFIG, {
        value: topicsCommunicationFrecuency,
        status: 'success',
      })
      notifications.dispatchSuccess()
    } catch (e) {
      dispatchTopicsFrecuencyState({ type: ACTIONS.ERROR })
      a9.dispatchEvent(a9.EVENTS.PD_SUBMIT_TOI_COMMUNICATIONS_CONFIG, {
        value: topicsCommunicationFrecuency,
        status: 'error',
      })
      notifications.dispatchError()
    }
  }

  return (
    <>
      <Typography>{texts.topicsMailFrecuency()}</Typography>
      <ToggleButtonGroup
        isLoading={topicsFrecuencyState.isLoading}
        options={Object.values(COMMUNICATIONS_OPTIONS)}
        selectedOption={topicsFrecuencyState.value}
        setSelectedOption={update}
        groupAriaLabel={texts.topicsMailFrecuency()}
        sx={styles}
      />
    </>
  )
}

Communications.propTypes = CommunicationsPropTypes

export default Communications
